

@import url('https://fonts.googleapis.com/css2?family=avenir:wght@300;400;500;600;700&display=swap');

@import "./colorThemes";
@import "./mediaQueries";
@import "./mixins";

body {
  margin: 0;
  font-family: 'avenir', sans-serif,"Avenir";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.Avenir {
  font-family: 'Avenir', sans-serif;

}

.backBlue {
  background-color: $appThemeDark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  text-align: center;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}



.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 10px;
  margin-bottom: 20px;
  // cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 18px;
  color: #414a53;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 7px;
  // border: 2px solid #cccccc;
}


/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
  background-color: #5265f9;
  border: 2px solid #5265f9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.__react_component_tooltip {
  max-width: 400px;
  height: max-content;
  word-break: break-word;
  font-size: 0.9rem !important;
  word-spacing: 2px;
  @media (max-width: $tablet) {
    font-size: 0.8rem !important;
  }
  @media (max-width: $phone) {
    font-size: 0.7rem !important;
  }
}

.input_field-error_text{
  color: red;
  margin-top: 0.3rem;
  font-size: 0.9rem
}

.view_textarea-text{
  padding: 0 20px;
  word-break: break-word;
  color: $appThemeText;
  font-weight: 500
}

.flex-center-center{
  @include flex-center-center;
}
.flex-center-spacebetween{
  @include flex-center-spacebetween;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-roo{
  font-family: 'Avenir', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
