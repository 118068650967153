@import '../../../colorThemes';
@import '../../../mixins';

.container{

    .title{
        color: $ALVFS-light-text;
        margin-bottom: 10px;
    }

    .upload_container{
        border-radius: 12px;
        padding: 20px;
        position: relative;
        height: 300px;
        width: 300px;
    
        .label{
            background-image: url('../../../../public/icons/upload.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: $ALVFS-light-bg;
            border-radius: 10px;
            // border: 1px dashed $ALVFS-border;
            display: block;
            height: 100%;
            width: 100%;
        }
    
        .image_preview{
            box-sizing: border-box;
            padding: 20px;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
}

.image-upload {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .dropzone {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    cursor: pointer;
  }
  
  .preview-image {
    max-width: 300px;
    margin-top: 10px;
  }

  