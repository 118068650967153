@use "../../mixins";
@import "../../colorThemes";
@import "../../mediaQueries";

.reset-container {
  padding: 4rem 2rem;
  background-color: #eef1fa;
  min-height: calc(100vh - 8rem);
  @media (max-width: $phone) {
    padding: 4rem 1rem;
  }
  .reset {
    padding: 2rem 1rem;
    border-radius: 10px;
    margin: auto;
    width: 40vw;
    resize: both;
    background-color: #ffffff;
    @include mixins.flex-center-center;
    // overflow-y: scroll;
    @media (max-width: $laptop) {
      width: 50vw;
    }
    @media (max-width: $tablet) {
      width: 80%;
    }
    @media (max-width: $phone) {
      width: 90%;
    }

    &__child {
      height: 100%;
      margin: auto;
      border-radius: 10px;
      padding: 20px 10px;
      @include mixins.flex-center-center;

      &__textbox {
        color: #fff;
        line-height: 1;
        @media (max-width: $tablet) {
          width: 80%;
        }
        @media (max-width: $phone) {
          width: 80%;
        }

        .aptillioLogo {
          text-align: center;
          img {
            max-width: 50%;
            min-width: 50%;
          }
        }
        .input-error {
          color: red;
        }

        .reset__heading {
          @include mixins.flex-center-center;
          color: $appThemeDark;
          font-size: 1.8rem;
          font-weight: normal;
          h3 {
            margin: 1rem;
          }
          @media (max-width: $tablet) {
            font-size: 1.5rem;
            h3 {
              margin: 1rem;
            }
          }
          @media (max-width: $phone) {
            font-size: 0.8rem;
            h3 {
              margin: 1rem;
            }
          }
        }

        .reset-success {
          @include mixins.flex-center-center;
          font-size: 1.2rem;
          font-weight: normal;
          color: black;
          text-align: center;
          margin: 3rem 5rem;
          @media (max-width: $tablet) {
            font-size: 1.2rem;
            margin: 2rem;
          }
          @media (max-width: $phone) {
            font-size: 0.8rem;
            margin: 2rem;
          }
        }

        .redirect_actions {
          font-size: 1rem;
          @include mixins.flex-center-center;
          p {
            color: rgb(35, 34, 34);
            cursor: pointer;
            font-weight: 500;
            &:hover {
              color: #5265f9;
            }
          }
        }
      }
      &-right {
        background-color: #ffffff;
      }
    }
  }
}

/*cellphones */

@media (max-width: 600px) {
}
