@mixin flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-spacebetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin flex-start-spacebetween {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

@mixin flex-center-spacearound {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin flex-center-flexstart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@mixin flex-center-flexend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@mixin flex($flex-direction, $align-items, $justify-content, $wrap){
    display: flex;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    flex-wrap: $wrap;
}

@function toRem($value) {
    $remValue: ($value / 16) + rem; 
    @return $remValue;
}

