@import '../../../colorThemes';
@import '../../../mixins';

.button{
    @include flex(row, center, center, unset);
    background-color: transparent;
    border: 2px solid $ALVFS-border;
    border-radius: 8px;
    color: $ALVFS-dark-text;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0 5px;
    padding: 0;

    .icon_container{
        padding: 10px;
        text-align: center;
        width: 20px;

        img{
            width: 100%;
            display: block;
        }
    }
}

.button_primary{
    background-color: $ALVFS-secondary;
    border: 1px solid $ALVFS-secondary;
    color: $ALVFS-primary;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 30px;
    margin: 20px 10px 0;
}

.button_disabled{
    background-color: $ALVFS-light-bg;
    border: 1px solid $ALVFS-light-bg;
    box-shadow: 1px 1px 1px 1px $ALVFS-border;
    color: $ALVFS-dark-text;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 30px;
    margin: 20px 10px 0;
}