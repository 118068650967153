@import "../../../mixins.scss";
@import "../../../mediaQueries";
@import "../../../colorThemes";

.input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  overflow: hidden;
  padding: 0 30px 0 20px;
  font-weight: 500;
  height: 45px;
  border-radius: 5px;
  border-width: 1px;
  border-top-style: solid;
  border-left-style: solid;
  border-color: #ccd0d8;
  color: #a9b1c2;
  font-size: 16px;

  input,
  textarea {
    max-width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;

    ::placeholder {
      font-size: 0.9rem;
      color: #a9b1c2;
    }

    button {
      // padding: 0.5rem 1.2rem;
      width: 100%;
      font-weight: 600;
      padding: 0 15px;
      // margin-bottom: 20px;
      -webkit-box-shadow: 0px 0px 10px 1px $appThemeWhite;
      -moz-box-shadow: 0px 0px 10px 1px $appThemeWhite;
      box-shadow: 0px 0px 10px 1px $appThemeWhite;
      text-transform: capitalize;

      svg {
        margin-right: 0.5rem;
      }
    }

    .primaryBtnTheme {
      color: $appButtonHoverDark;
      &:hover {
        background-color: $appButtonHoverDark;
        color: $appThemeWhite;
      }
    }

    .secondaryBtnTheme {
      color: $appThemeWhite;
      background-color: $appButtonHoverDark;
      &:hover {
        background-color: $appThemeBackground;
        color: $appButtonHoverDark;
      }
    }

    label {
      margin-top: 6px;
      font-size: 0.8rem;
      color: #8d98af;
      margin-left: 0px;
      font-weight: 400;
      text-transform: capitalize;
      @include flex-center-flexstart;
      span {
        display: flex;
        margin-left: 5px;
        font-size: 1rem;
        cursor: pointer;
        color: black;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@media screen and (max-width: 720px) {
  .input {
    width: 100% !important;
    min-width: 100% !important;
  }
}

@media screen and (max-width: $phone) {
  .input {
    padding: 0 10px;
  }
}

.input__container input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  color: #858585;
}

.input__container textarea {
  padding: 12px 20px;
  min-height: 43px;
}

.input__container button {
  cursor: pointer;
  @include flex-center-center;
}

.input__wrapper {
  position: relative;
  display: inline-block;

  overflow: hidden;

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 3px;
    cursor: pointer;
    z-index: 999;
  }

  textarea {
    min-height: 8rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .input {
    width: 100% !important;
    min-width: 100% !important;
    margin-left: 0 !important;
  }

  .input__container {
    width: 100% !important;
    min-width: 100% !important;
  }

  .input__container label {
    margin-left: 10px !important;
  }

  .dropped {
    margin-left: 0 !important;
    margin-top: 20px !important;
  }
}

.password-tooltip {
  max-width: 300px;
}
