@use "../../mixins";
@import "../../colorThemes";
@import "../../mediaQueries";

.homepage_container {
  box-sizing: border-box;
  height: 100vh;
  position: relative;

  .homepage_wrapper{
    height: inherit;
  }

  @media (max-width: $phone) {
    padding: 1rem;
    height: max-content;
    height: 100vh;
  }
}
