@import '../../../mixins';
@import '../../../colorThemes';

.filter_drawer{
    background-color: $appThemeWhite;
    width: 360px;
    min-width: 30vh;
    &-wrapper{
        height: 100vh;
        &_header{
            box-sizing: border-box;
            height: 12%;
            min-height: 3rem;
            padding: 1.5rem;
            background-color: $appThemeLight;
            &-wrapper{
                height: 100%;
                @include flex-center-spacebetween;
                
                .filter-header{
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: $appThemeText;
                }

                .filter-header_btn{
                    max-height: 3rem;
                    min-height: 2.2rem;
                    max-width: 3rem;
                    min-width: 2.2rem;
                    border-radius: 50px;
                    background-color: $appThemeWhite;
                    color: $appThemeText;

                    &_icon{
                        @include flex-center-center;
                        font-weight: bolder;
                        font-size: 1.5rem;
                    }
                    &:hover{
                        background-color: $appThemeDark;
                        color: $appThemeWhite;
                    }
                }
            }
        }
        .filter_drawer-main{
            min-height: 77%;
            box-sizing: border-box;
            padding: 3rem 1.5rem;
            overflow-y: auto;
            &_wrapper{
                .filter-input{
                    margin-bottom: 20px
                }
            }
        }
        &_footer{
            box-sizing: border-box;
            height: 10%;
            min-height: 4rem;
            padding: 1rem;
            background-color: $appThemeLight;
            &-wrapper{
                height: 100%;
                @include flex-center-spacebetween;
                .filter-footer_btn{
                    height: 100%;
                    min-height: 7vh;
                    width: 40%;
                    border-radius: 5px;
                    color: $appThemeText;
                    background-color: $appThemeBackground;
                    font-weight: bold;
                    font-size: 1rem;

                    &:hover{
                        background-color: $appThemeDark;
                        color: $appThemeWhite;
                    }
                }
            }
        }
    }
}