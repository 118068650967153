@import '../../../../colorThemes';
@import '../../../../mixins';

.container{

    .wrapper{

        .form_container{
            padding: 20px 10px;

            .input_container{
                @include flex(row, center, center, unset);
            }

            .action_button{
                @include flex(row, center, flex-end, unset);
            }
        }
    }
}