
$appThemeDark : #5367FD;
$appButtonHoverDark : #5165F8;
$appThemeLight : #cfd5fa;
$appThemeBackground : #eef1fa;
$appThemeWhite: #ffffff;
$appThemeText: #3f464f;
$appThemeWarnBg: rgb(225, 112, 112);

$ALVFS-primary: #FFFFFF;
$ALVFS-secondary: #0E4D92;
$ALVFS-heading: #080908;
$ALVFS-light-text: #A9B1C2;
$ALVFS-dark-text: #8D98AF;
$ALVFS-light-bg: #F4F9FF;
$ALVFS-border: #CCD0D8;
$ALVFS-bg: #F5F5F5;
