@import '../../../colorThemes';
@import '../../../mixins';

.container{
    @include flex(row, center, center, unset);
    margin: 0 5px;

    .active, .inactive{
        @include flex(row, center, center, unset);
        border-radius: 10px;
        padding: 10px;

        span{
            padding-left: 10px;
        }
    }

    button:first-child{
        border-radius: 10px 0 0 10px;
    }

    button:last-child{
        border-radius: 0 10px 10px 0;
    }

    .active{
        background-color: $ALVFS-secondary;
        border: 2px solid $ALVFS-secondary;
        color: $ALVFS-primary;
    }

    .inactive{
        background-color: $ALVFS-primary;
        border: 2px solid $ALVFS-border;
        color: $ALVFS-dark-text;
    }
}