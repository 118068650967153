@import '../../../mixins'; 
@import '../../../colorThemes'; 

.selectbox{
    min-width: 100%;

    &-wrapper{

        .selectbox-label{
            margin-bottom: 6px;
            font-size: 1rem;
            color: #6b6a6a;
            margin-left: 0px;
            font-weight: 600;
            text-transform: capitalize;
            @include flex-center-flexstart;
            span{
                display: flex;
                margin-left: 5px;
                font-size: 1.2rem;
                cursor: pointer;
                color: black;
            }
        }

        .select {
            .select__control{
                height: 50px;
                border-radius: 8px;
                background-color: #eef1fa;
                border:0;
                .select__input-container, .select__single-value{
                    // color: $appThemeText;
                    font-weight: 600;
                    padding: 1px 10px 2px 12px;
                }
                .select__indicator{
                    padding: 8px 15px;
                    color: $appThemeDark;
                }
                .select__indicator-separator{
                    background: $appThemeDark;
                    padding: 1px;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
          
        }

        .css-4ljt47-MenuList {
            max-height: 12rem;
        }
    }
}