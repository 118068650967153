@use "../../../mixins";
@import "../../../colorThemes";
@import "../../../mediaQueries";

.toaster-container {
	font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;

	@media (max-width: $smallTablet) {
		font-size: 0.8rem;
	}

	@media (max-width: $phone) {
		font-size: 0.6rem;
	}

}

.top-right {
	top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.toaster {
	background: $appThemeLight;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 30px;
	margin-bottom: 15px;
	width: 300px;
	max-height: 100px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;

	button{
		color: $appThemeDark !important;
		font-size: 800;
	}
}

.toaster:hover {
	box-shadow: 0 0 12px #fff;
	opacity: 1;
	cursor: pointer
}

.toaster-title {
	font-weight: 700;
	font-size: 1.1rem;
	text-align: left;
	margin-top: 0;
	margin-bottom: 10px;
    width: 300px;
    // height: 20px;
    height: fit-content;
    color: $appThemeDark;
}

.toaster-message {
	margin: 0;
	text-align: left;
	font-size: 0.9rem;
    margin-left: 45px;
    color: $appThemeDark;
    font-weight: 600;
	word-break: break-word;
}

.toaster-image {
    float: left;
    margin-right: 15px;
}

.toaster-image img {
    width: 30px;
    height: 30px;
}

.toast {
    height: fit-content;
    width: 365px;
	color: #fff;
	padding: 17px 15px 15px 10px;

	@media (max-width: $phone) {
		width: 280px;
	}
	
}

.toaster-container button {
	position: relative;
	right: -.3em;
	top: -.3em;
	float: right;
	font-weight: 700;
	color: #fff;
	outline: none;
	border: none;
	text-shadow: 0 1px 0 #fff;
	opacity: .8;
	line-height: 1;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}