@import '../../../colorThemes';
@import '../../../mixins';

.container{

    .wrapper{
        @include flex(row, center, flex-start, unset);

        .heading{
            padding-left: 10px;

            .description{
                color: $ALVFS-light-text;
            }

            .title{
                color: $ALVFS-dark-text;
            }
        }
    }
}