@import '../../../../colorThemes';
@import '../../../../mixins';

.container{
    padding: 2rem;

    .wrapper{

        .back{
            margin-bottom: 20px;

            img{
                cursor: pointer;
                width: 50px;
            }
        }

        .heading{
            @include flex(row, center, space-between, unset);

            .action_buttons{
                @include flex(row, center, space-between, unset);
            }
        }

        .details{
            @include flex(row, flex-start, space-between, unset);
            border: 1px solid $ALVFS-border;
            border-radius: 8px;
            margin: 20px 0;
            padding: 2rem;

            .profile_image{
                height: 150px;
                width: 150px;

                img{
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }

            .profile_details{
                width: calc(100% - 180px);

                .divider{
                    border-top: 1px solid $ALVFS-border;
                }

                .column{
                    padding: 1rem 0;

                    .row{
                        @include flex(row, flex-start, flex-start, wrap);
                        margin-bottom: 2rem;

                        .sub_detail{
                            margin-bottom: 2rem;
                            width: 32%;

                            .label{
                                color: $ALVFS-dark-text;
                                font-size: 1rem;
                                font-weight: 500;
                            }

                            .value{
                                color: $ALVFS-light-text;
                                font-size: 1.2rem;
                                margin-top: 5px;
                            }
                        }
                    }
                }

                .astrology_details{

                    .sub_heading{
                        padding: 10px 0;
                    }

                    .astrologers{
                        @include flex(row, flex-start, space-between, unset);
                        padding: 1rem 0;

                        .column{
                            width: calc(100% - 32%);

                            .row{
                                @include flex(row, flex-start, space-around, unset);
                                margin-bottom: 2rem;
        
                                .sub_detail{
                                    width: 45%;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}