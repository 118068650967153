@import '../../../../colorThemes';
@import '../../../../mixins';

.container{
    padding: 2rem;

    .wrapper{

        .back{
            margin-bottom: 20px;

            img{
                cursor: pointer;
                width: 50px;
            }
        }

        .heading{
            @include flex(row, center, flex-start, unset);

            .app_user{
                @include flex(row, center, flex-start, unset);
                background-color: $ALVFS-light-bg;
                border-radius: 10px;
                color: $ALVFS-secondary;
                font-size: 1.2rem;
                padding: 15px 30px;
                min-width: 400px;
                margin-left: 20px;
    
                .app{
                    font-weight: 600;
                    margin: 0 10px;
                }
    
                .user{
                    font-style: italic;
                    font-weight: 400;
                    margin-left: 10px;
                }
            }
        }

        .form_container{
            @include flex(row, flex-start, flex-start, unset);
            margin-top: 2rem;

            .upload{
                width: 380px;
            }

            form{
                width: calc(100% - 380px);

                .divider{
                    border-top: 1px solid $ALVFS-border;
                    margin-top: 20px;
                }

                .form_container{
                    @include flex(row, flex-end, flex-start, wrap);

                    .action_button{
                        box-sizing: border-box;
                        width: 47%;
    
                        button{
                            width: 100%;
                        }
                    }
                }
            }
        }
        
    }
}