@import '../../../colorThemes';
@import '../../../mixins';

.container{
    height: inherit;

    .wrapper{
        @include flex(row, unset, center, unset);
        height: inherit;

        .sidebar{
            width: 300px;
        }

        .layout{
            @include flex(column, unset, flex-start, unset);
            width: calc(100% - 300px);
        }
    }
}