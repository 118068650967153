@import '../../../colorThemes';
@import '../../../mediaQueries';
@import '../../../mixins';

.homeLayout-container{
    .homeLayout-wrapper{
        @include flex(row, center, center, unset);

        .homeLayout-header{
            max-width: 100vw;
        }

        .homeLayout{

            .homeLayout-screen{
                min-height: calc(100vh - 177.691px);
            }

            &-sidebar_main{
            }

            &-screen{
                width: 100%;
            }

            &-right{
                background-color: #EEF1FA;
                .homeLayout-right-main{
                    width: 3vw;
                }
            }
            // &-end-border{
                // height: 3rem;
                // background-color: $appThemeBackground;
                // border-bottom: 2rem solid $appThemeBackground;
            // }
        }
    }
}

//cellphones
@media (max-width: 600px) {
    .homeLayout-right{
        display: none;
    }
}