.progressloader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8AD;

    .progressloader-main{
        left: 50%;
        top: 40%;
        z-index: 9;
        position: absolute;
    }
}