@use "../../mixins";
@import "../../colorThemes";
@import "../../mediaQueries";

.login-left-text-div {
  position: relative;
  height: 100%;

  .content{
    color: $ALVFS-primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;

    .top-text-p {
      font-size: 2rem;
      font-style: italic;
      margin-bottom: 2rem;
    }

    .middle-text-p {
      font-size: 4.5rem;
      font-weight: 900;
      line-height: 65px;
      font-style: italic;
    }
    .bottom-text-p {
      font-size: 1.2rem;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1225px){

  .login-left-text-div {
    // position: relative;
    // height: 100%;
  
    .content{
      // color: $ALVFS-primary;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      width: 80%;
  
      .top-text-p {
        font-size: 1.8rem;
        // font-style: italic;
        margin-bottom: 1.8rem;
      }
  
      .middle-text-p {
        font-size: 3.5rem;
        // font-weight: 900;
        line-height: 60px;
        // font-style: italic;
      }
      .bottom-text-p {
        // font-size: 1.2rem;
        margin-top: 1.8rem;
      }
    }
  }
}

.login-welcome-text {
  align-self: baseline;
  color: #191f2d;
  font-weight: 900;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 70px;
  font-size: 26px;
}

.login-right-highlight {
  color: #8d98af;
  font-size: 16px;
  align-self: baseline;
  margin-bottom: 20px;
}

.text-above-input {
  margin-bottom: -15px;
  font-size: 16px;
  color: #8d98af;
  margin-top: 20px;
}

.forget-password-container {
  display: inline-flex;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .MuiTypography-root {
    font-size: "12px";
  }
}
.remember-me-text {
  font-size: 16px;
  color: #8d98af;
  font-weight: 500;
}

.forget-password-text {
  cursor: pointer;
  color: #0e4d92;
  text-decoration: underline;
  font-size: 16px;
  letter-spacing: 0;
  opacity: 1;
  font-weight: 800;
}
