.center_modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .center_modal.open {
    display: flex;
  }
  
  .center_modal_content {
    background-color: white;
    padding: 3rem;
    border-radius: 4px;
    max-width: 600px;
    width: 100%;
    max-height: 80vh;
    position: relative;
  }
  
  .close {
    background-color: transparent;
    position: absolute;
    top: -20px;
    right: -25px;
  }
  