@import '../../../colorThemes';
@import '../../../mixins';

.heading_container{

    .heading_wrapper{
        @include flex(row, center, space-between, unset);
        width: 100%;

        .icon{
            margin-right: 10px;
            
            img{
                display: block;
            }
        }

        .heading{
            width: 100%;

            .title{
                color: $ALVFS-heading;
                font-size: 2rem;
            }

            .description{
                color: $ALVFS-light-text;
                padding-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 1390px){

    .heading_container{

        .heading_wrapper{
    
            .icon{
                width: 60px;
                
                img{
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
    
            .heading{
    
                .title{
                    font-size: 1.6rem;
                }
    
                .description{
                    font-size: 0.8rem;
                }
            }
        }
    }
}