@import "../../../mixins";
@import "../../../colorThemes";

.table_container{
  .table_wrapper{
    font-size: smaller;
    .table_search{
      &-wrapper{
        margin-bottom: 1rem;
      }
    }
    .table_main{
      border: 1px solid #cccccc;
      border-radius: 10px 10px 0 0;

      table{
        border-radius: 10px;
        background-color: $appThemeDark;
        width: 100%;
        border-spacing: unset;
        border-collapse: unset;
        font-size: 0.8rem;

        th{
          color: $appThemeWhite;
          text-align: left;
          padding: 10px 12px;
          span{
            font-size: larger;
            font-weight: 600 !important;
          }
        }

        .react-bootstrap-table th .caret-4-desc:before {
            margin-left: 3.5px;
            content: "\2191";
            opacity: .4;
            font-size: larger;
        }

        tbody{
          background-color: $appThemeWhite;
          tr{
            font-weight: 500;
            .react-bs-table-no-data{
              text-align: center;
            }

            td{
              padding: 10px 12px;
              text-align: left;
              word-break: break-word;
              .table_action{
                position: relative;
                &-icon{
                  .action-icon{
                    cursor: pointer;
                    min-height: 20px;
                    height: 10%;
                    min-width: 20px;
                    width: 10%;
                    &:hover{
                      color: $appThemeDark;
                      cursor: pointer;
                    }
                  }
                }
                &-popup{
                  z-index: 1;
                  background-color: $appThemeWhite;
                  color: $appThemeText;
                  -webkit-box-shadow: 0px 0px 15px 0px rgba(158,156,158,0.7);
                  -moz-box-shadow: 0px 0px 15px 0px rgba(158,156,158,0.7);
                  box-shadow: 0px 0px 15px 0px rgba(158,156,158,0.7);
                  border-radius: 10px;
                  position: absolute;
                  right: 42%;
                  width: max-content;
                  min-width: 10rem;
                  padding: 0 10px;
                  &_wrapper{
                    padding: 2px 5px;
                    .action_popup{
                      display: flex;
                      padding: 10px 0;
                      border-top: 0.5px solid rgb(228, 225, 225);
                      cursor: pointer;
                      &-icon{
                        @include flex-center-center;
                        margin-right: 10px;
                        img{
                          height: 15px;
                          width: 20px;
                        }
                      }
                      &-text{
                        font-size: 0.8rem;
                        font-weight: 500;
                      }
                      &:hover{
                        color: $appThemeDark;
                      }
                      
                      &:first-child{
                        border-top: 0;
                      }
                    }
                  }
                }
              }
            }

          }

        }
      }
      
      
      .table-striped tbody tr:nth-of-type(odd) {
        background-color: $appThemeBackground;
      }

      .react-bootstrap-table-pagination{
        .react-bs-table-sizePerPage-dropdown{
          display: none;
        }

        .react-bootstrap-table-pagination-list{
          margin: auto;
          width: min-content;
          min-width: 20%;
          .react-bootstrap-table-page-btns-ul{
            @include flex-center-center;
            list-style: none;
            padding-left: 0;
            .page-item{
              margin-left: 1.5rem;
              display: flex;
              a{
                text-decoration: none;
                .prev,.next{
                  @include flex-center-center;
                }
                .prev>svg{
                    margin-right: 5px;
                }
                .next>svg{
                  margin-left: 5px;
              }
              }
              &:nth-child(1){
                margin: unset
              }
            }
  
            .active{
              @include flex-center-center;
              background-color: $appThemeDark;
              border-radius: 50px;
              font-weight: 500;
              height: 1.5rem;
              width: 1.5rem;
              padding: 2px;
              a{
                color: $appThemeWhite;
              };
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .table_container{
    .table_wrapper{
      .table_main{
        // table{  
          width: 100%;
  
          th{
            font-size: 0.5rem;
            text-align: left;
            padding: 5px;
            word-break: break-word;
            
          }
  
          tbody{
            tr{
              td{
                font-size: 0.5rem;
                padding: 5px;
                text-align: left;
                word-break: break-word;
              }
            }
  
            tr:hover{
              background-color: $appThemeLight;
              color: $appThemeDark;
            }
          }
        }
        &-header{
          background-color: $appThemeDark;
          color: $appThemeWhite;
        }
        
        .table-striped tbody tr:nth-of-type(odd) {
          background-color: #EEF1FA;
        }
      }
    // }
  }
}