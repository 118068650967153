@import '../../colorThemes';
@import '../../mixins';

.container {
    height: 100vh;

    .wrapper {
        @include flex(row, unset, center, unset);
        position: relative;
        height: 100%;

        .left {
            background-color: $ALVFS-secondary;
            @include flex(column, center, center, unset);
            width: 60%;

            .content {
                color: $ALVFS-primary;
                width: 70%;

                .top_text {
                    font-size: 2rem;
                    font-style: italic;
                    margin-bottom: 2rem;
                }

                .middle_text {
                    font-size: 4.5rem;
                    font-weight: 900;
                    line-height: 65px;
                    font-style: italic;
                }

                .bottom_text {
                    font-size: 1.2rem;
                    margin-top: 2rem;
                }
            }
        }

        .right {
            @include flex(column, center, center, unset);
            width: 40%;
        }
    }
}

@media screen and (max-width: 1550px) {

    .container {

        .wrapper {

            .left {

                .content {

                    .middle_text {
                        font-size: 4rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1375px) {

    .container {

        .wrapper {

            .left {

                .content {
                    width: 75%;
                }
            }
        }
    }
}

@media screen and (max-width: 1285px) {

    .container {

        .wrapper {

            .left {

                .content {

                    .middle_text {
                        font-size: 3.5rem;
                        line-height: 55px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1125px) {
    .container {

        .wrapper {

            .left {

                .content {
                    width: 80%;

                    .top_text {
                        font-size: 1.6rem;
                    }

                    .middle_text {
                        font-size: 3rem;
                        line-height: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {

    .container {

        .wrapper {
            @include flex(column, flex-start, flex-start, unset);

            .left, .right {
                box-sizing: border-box;
                padding: 2rem;
                width: 100%;

                .content{
                    
                    .top_text {
                        font-size: 1.5rem;
                    }

                    .middle_text {
                        font-size: 2.5rem;
                        line-height: 40px;
                    }

                    .bottom_text {
                        font-size: 1rem;
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
}