@import '../../../colorThemes';
@import '../../../mixins';

.container{
    background-color: $ALVFS-primary;
    margin: 2rem;

    .wrapper{

        .title_filters{
            @include flex(row, center, space-between, wrap);

            .filters{
                @include flex(row, center, space-between, unset);
            }
        }

        .listing{
            margin-top: toRem(30);
        }
    }
}

@media screen and (max-width: 1280px){

    .container{
    
        .wrapper{
    
            .title_filters{
    
                .filters{
                   margin-top: 2rem;
                }
            }
        }
    }
}