@use "../../mixins";
@import "../../colorThemes";
@import "../../mediaQueries";

* {
  font-family: "Avenir", sans-serif !important;
}
.main-dialogue-box {
  height: 65%;
  width: 40%;
}

.dialogue-title {
  text-align: center;
  color: #080908;
  font-size: 25px;
  font-weight: 900;
}
.input-error {
  color: red($color: #000000);
  font-size: 16px;
}

.dialogue-description {
  text-align: center;
  color: #a9b1c2;
  font-size: 12px;
  width: 75%;
  margin: 0 auto 20px auto;
}

.back-to-login {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
}

.dialogue_box-close {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 8px;
  color: $appThemeWhite;
  border-radius: 50%;
  background-color: #0e4d92;
  height: 30px;
  width: 30px;
  font-weight: bold;
}
