@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.dashboard_container{
  .dashboard_wrapper{
    .dashboard_main{
      padding: 20px;
      &-message{
        margin: 10px;
        font-size: clamp(1.1rem, 0.4203rem + 1.4215vw, 2.2rem);
      }

      &-grid{
        // max-width: 75vw;
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-gap: 40px;
        background-color: $appThemeWhite;
        padding: 30px 0;

        .item{
          // @include flex-center;  
          padding: 15px;
          background-color: $appThemeBackground;
          text-align: center;

          .item_content{
            padding: 15px;
            font-size: 0.9rem;
            .content_text{
              // font-size: clamp(1rem, 0.7857rem + 0.5714vw, 1.5rem);
            }

            .content_number{
              font-size: clamp(3rem, 2.5000rem + 1.3333vw, 4rem);
              color: $appThemeDark;
            }
          }
        }
      }

      &-header_container{
        margin: 10px;
        margin-bottom: 30px;
        @include flex-center-spacebetween;
        .dashboard_main-listing_header{
          max-width: 42%;
          color: $appThemeText;
          &_text{
            font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
            text-transform: capitalize;
          }
          @include flex-center-spacebetween;
        }

        .dashboard_main-listing_header_actions{
          @include flex-center-flexend;
          max-width: 52%;
          cursor: pointer;
          
          &-search, &-filter{
            margin-left:10px;
            font-size: 0.9rem;
            button{
              font-weight: 600;
            }
          }
        }
  
      }

      &-list_table{
        margin: 20px 0;
      }
    }
  }
}

//smaller screens
@include media('<=desktop') {
  .dashboard_container{
    .dashboard_wrapper{
      .dashboard_main{
        &-grid{
          gap: 20px;
  
          .item{
            padding: 10px;
  
            .item_content{
              padding: 10px;
            }
          }
        }
        &-list_table{
          margin: 15px 0;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .dashboard_container{
    .dashboard_wrapper{
      .dashboard_main{
        padding: 20px;
        &-message{
          // font-size: clamp(1.3rem, 0.5203rem + 1.7215vw, 2.5rem);
        }
  
        &-grid{
          // max-width: 75vw;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 20px;
          padding: 20px 0;
  
          .item{
            // @include flex-center;
            // max-width: 25vw;  
            padding: 15px;
            background-color: $appThemeBackground;
            text-align: center;
  
            .item_content{
              padding: 15px;
              .content_text{
                // font-size: clamp(1rem, 0.7857rem + 0.5714vw, 1.5rem);
              }
  
              .content_number{
                font-size: clamp(3rem, 2.5000rem + 1.3333vw, 4rem);
                color: #5774F9;
              }
            }
          }
        }
        &-list_table{
          margin: 20px 0;
        }
      }
    }
  }
}

@include media('<=phone') {
  .dashboard_container{
    .dashboard_wrapper{
      .dashboard_main{
        &-message{
          @include flex-center-center;
        }
  
        &-grid{
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(4, auto);
  
          .item{
            padding: 15px;
          }
        }
  
        &-listing_header{
          margin-top: 10px;
          @include flex-center-center;
        }
  
        &-list_table{
          margin: 10px 0;
        }
      }
    }
  }
}