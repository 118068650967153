@import '../../../colorThemes';
@import '../../../mixins';

.input_fields_container {

    label {
        color: $ALVFS-dark-text;
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
        margin: 10px;
    }

    .input_field {
        @include flex(row, center, center, unset);
        background-color: $ALVFS-primary;
        border-radius: 8px;
        border: 2px solid $ALVFS-border;
        color: $ALVFS-dark-text;
        font-size: 0.9rem;
        font-weight: 600;
        margin: 0 10px;
        position: relative;

        input {
            background-color: $ALVFS-primary;
            box-sizing: border-box;
            border: none;
            border-radius: 8px;
            color: $ALVFS-dark-text;
            font-weight: 600;
            padding: 14px;
            width: 100%;
        }

        input::placeholder {
            color: $ALVFS-dark-text;
        }

        input[type="search"]::-webkit-search-cancel-button {
            display: none;
        }

        svg {
            position: absolute;
            right: 10px;
            font-size: 1.2rem;
            font-weight: 300;
        }

        .dropdown_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 15px;
            width: 100%;
        }

        .dropdown_list {
            border-radius: 8px;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: $ALVFS-primary;
            box-shadow: 0px 1px 4px #e7e7e7;
            list-style-type: none;
            padding: 0;
            margin: 5px 0 0;
            z-index: 9;

            li {
                border-radius: 8px;
                color: $ALVFS-secondary;
                font-size: 0.7rem;
                padding: 15px;
                cursor: pointer;
            }

            li:hover {
                background-color: $ALVFS-light-bg;
            }
        }
    }

    .radio_field{
        @include flex(row, center, flex-start, unset);

        .radio_container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          
          /* Hide the browser's default radio button */
          .radio_container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          
          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $ALVFS-primary;
            border-radius: 5px;
            border: 2px solid $ALVFS-border;
          }
          
          /* On mouse-over, add a grey background color */
          .radio_container:hover input ~ .checkmark {
            background-color: #ccc;
          }
          
          /* When the radio button is checked, add a blue background */
          .radio_container input:checked ~ .checkmark {
            background-color: white;
          }
          
          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          
          /* Show the indicator (dot/circle) when checked */
          .radio_container input:checked ~ .checkmark:after {
            display: block;
          }
          
          /* Style the indicator (dot/circle) */
          .radio_container .checkmark:after {
               top: 5px;
              left: 5px;
              width: 15px;
              height: 15px;
              border-radius: 4px;
              background: #0E4D92;
          }
    }
}