@import '../../../colorThemes';
@import '../../../mixins';

.table_container {
    background-color: $ALVFS-primary;
    overflow-x: auto;
    max-width: 100%;
    margin-bottom: 1rem;

    table {
        width: 100%;
        // table-layout: fixed;
        border-collapse: collapse;

        th,
        td {
            color: $ALVFS-light-text;
            white-space: nowrap;
            padding: 0.5rem;

            &:first-child {
                width: 3%;
                text-align: center;
            }

            &:last-child {
                text-align: center;
                width: 100px;
            }
        }

        th {
            color: $ALVFS-dark-text;
            font: normal normal 600 0.7rem/0px Avenir;
            position: sticky;
            top: 0;
            z-index: 1;
    
            input[type="checkbox"] {
                &:first-child {
                    margin-right: 5px;
                }
            }
        }
    
        thead {
    
            tr {
    
                th {
                    padding: 25px 15px;
                }
            }
        }
    
        tbody {
    
            tr {
                &:hover {
                    background-color: $ALVFS-light-bg;
                }
    
                td {
                    font-size: 0.9rem;
                    padding: 20px;
                    text-align: center;
    
                    .action_button{
                        background-color: transparent;
                        margin: 0 2px;
                    }
                }
            }
    
            .active {
                background-color: $ALVFS-light-bg;
            }
        }
    }

    .pagination{
        @include flex(row, center, center, unset);
        margin: 15px;
        text-align: center;

        .previous, .next{
            background-color: $ALVFS-light-bg;
            border-radius: 4px;
            padding: 8px;

            svg{
                display: block;
            }
        }

        button{
            background-color: transparent;
            color: $ALVFS-light-text;
            font-size: toRem(10);
            margin-right: 10px;
        }

        .active{
            background-color: $ALVFS-secondary;
            border-radius: 4px;
            color: $ALVFS-primary;
            padding: 5px 8px;
        }
    }

    @media (max-width: 767px) {
        table {

            th,
            td {
                min-width: 100px;
                font-size: 12px;

                // &:first-child {
                //     width: 1%;
                //     padding: 0.2rem;
                // }
            }
        }
    }
}

/* The container */
.checkbox_container {
    display: block;
    position: relative;
    top: -3px;
    transform: translate(-10px, 0px);
    left: 50%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $ALVFS-primary;
    border: 1px solid $ALVFS-border;
    border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked~.checkmark {
    background-color: #0E4D92;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}