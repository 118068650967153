@import "../../../mixins";
@import "../../../colorThemes";

.notification-container{
    background-color: $appThemeWhite;
    position: absolute;
    right: 5%;
    top: 115%;
    // padding: 1rem;
    color: $appThemeText;
    z-index: 9;
    border-radius: 10px 3px 10px 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
        
    ::-webkit-scrollbar {
        width: 6px;
        border-radius: 50px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #cfd5fa;
        border-radius: 50px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $appThemeDark;
        border-radius: 50px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .notification-wrapper{
        width: 280px;
        max-height: 500px;
        overflow-y: auto;
        .notification_header{
            padding: 1rem;
            padding-bottom: 0;
            @include flex-center-spacebetween;
            margin-bottom: 1rem;
            color: $appThemeDark;
            &-text{
                font-size: 1.2rem;
                font-weight: 500;
            }
            &-mark{
                @include flex-center-center;
                color: $appThemeDark;
                font-weight: 600;
                &_icon, &_text{
                    display: flex;
                    margin: 0 5px;
                }
                &_icon{
                    font-size: 1.2rem;
                }
                &_text{
                    font-size: 0.8rem
                }
            }
        }
        .notification_list{
            .notification{
                @include flex-center-spacebetween;
                align-items: flex-start;
                padding: 1rem 0;
                border-top: 2px solid rgb(238, 236, 236);
                &-icon{
                    @include flex-center-center;
                    align-items: flex-start;
                    margin-right: 10px;
                    margin-top: 3px;
                }
                &-content{
                    padding: 0 1rem;
                    &_header{
                        margin-bottom: 1rem;
                        font-size: 0.9rem;
                        font-weight: 500;
                        color: $appThemeDark;
                    }
                    &_text{
                        margin-bottom: 1rem;
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                    &_detail{
                        @include flex-center-spacebetween;
                        &-time{
                            font-size: 0.72rem;
                            font-weight: 500;
                        }
                        &-actions{
                            @include flex-center-spacebetween;
                            &_eye,&_close{
                                display: flex;
                                padding: 0 5px;
                            }
                            &_close{
                                color: $appThemeDark;
                            }
                        }
                    }
                }

                &-loader{
                    margin: auto;
                    img{
                        height: 3rem;
                    }
                }
    
                &:hover{
                    background: $appThemeBackground;
                    cursor: default;
                }
            }
        }
    }
}
