@import '../../../mixins';
@import '../../../colorThemes';

*{
    font-family: 'Avenir', sans-serif !important;
}

.dialogue_box-close{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 8px;
    color: $appThemeWhite;
    border-radius: 50%;
    background-color: $appThemeDark;
    @include flex-center-center;
}